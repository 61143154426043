body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3eb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home-background {
  background: linear-gradient(225deg, #ff9100 0%, #ffc67a 100%);
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 10;
}

.home {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 999;
  border: 1px solid #ff910010;
}

.home-main-div {
  width: 90%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.home-brand {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 30px;
  font-weight: 800;
  color: #fff;
  margin-top: 36px;
  margin-left: 50px;
}

@media (max-height: 550px) {
  .home-brand {
    display: none;
  }
}

@media (max-width: 800px) {
  .home-header {
    width: 100%;
  }
}

.home-header {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 70px;
  font-weight: 800;
  line-height: 90px;
  color: #fff;
  margin-left: 50px;
  width: 95%;
}

@media (max-width: 1000px) {
  .home-header {
    font-size: 60px;
    line-height: 80px;
  }
}

@media (max-width: 800px) {
  .home-header {
    font-size: 50px;
    line-height: 70px;
  }
}

@media (max-width: 650px) {
  .home-header {
    font-size: 40px;
    line-height: 60px;
  }
}

.home-button-div {
  margin-top: 20px;
}

#home-button {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 30px;
  background-color: #fff;
  color: #fcac45;
  padding: 8px 20px;
  margin-top: 10px;
  margin-left: 50px;
  border-radius: 10px;
}

@media (max-width: 800px) {
  #home-button {
    font-size: 25px;
  }
}

@media (max-width: 650px) {
  #home-button {
    font-size: 20px;
  }
}

#home-button:hover {
  box-shadow: 8px 8px #ffffff80;
}

.bi-arrow-right-circle {
  margin-top: 15px;
  margin-left: 15px;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #f3f3eb;
  border-bottom: 1px solid #dcdcdc; 
  margin-bottom: 30px;
  padding-right: 15px;
  z-index: 5;
}

.navbar-brand {
  color: #fcac45;
  font-size: 22px;
  font-weight: 800 !important;
  margin-left: 10px;
  font-family: Avenir, ui-sans-serif, system-ui;
  font-weight: 500;
}

.navbar-brand:hover {
  color: #fcac45;
}

.navbar-collapse {
  background-color: #f3f3eb;
  margin-top: 5px;
}

.navbar-toggler {
  display: flex;
  width: 50px;
  height: 50px;
  padding: 0px;
}

.navbar-toggler:focus, .navbar-toggler:active {
  outline: none !important;
  box-shadow: none;
}

.bi-list {
  fill: #fcac45;
  width: 40px;
  height: 40px;
  margin: auto;
}

.nav-item {
  margin: 10px 5px 10px 10px;
  font-family: Avenir, ui-sans-serif, system-ui;
}

.dropdown-menu {
  width: 100px;
}

#nav-link {
  color: black;
  display: inline;
}

.nav-link {
  margin-bottom: -10px;
}

.nav-link:hover {
  color: black;
  text-decoration: underline;
}

.navlink {
  color: black;
}

.navlink:hover {
  background-color: #fff;
  text-decoration: underline;
  cursor: pointer;
}

.nav-auth {
  margin-top: 8px;
}

.nav-login {
  font-family: Avenir, ui-sans-serif, system-ui;
  align-self: center;
  text-decoration: none;
  margin: 0px 20px 10px 10px;
}

.nav-login:hover {
  text-decoration: underline;
}

.nav-signup {
  margin: 5px 0px 15px;
}

#logout-item {
  border-top: 1px solid #dcdcdc;
  margin-top: 10px;
  padding-top: 10px;
}

.normal-alert {
  font-family: Avenir, ui-sans-serif, system-ui;
  color: black;
  background-color: #fdfdf8;
  border: 1px solid #fdfdf8;
  border-radius: 10px;
}

.error-alert {
  font-family: Avenir, ui-sans-serif, system-ui;
  color: black;
  border: none;
  border-radius: 10px;
}

#alert-link {
  color: #fcac45;
}

#alert-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

#error-alert-link {
  color: black;
}

#project-alert {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.btn-primary {
  background-color: #fcac45;
  border: 1px solid #fcac45;
  font-weight: bold;
  font-size: 18px;
  transition: box-shadow 0.5s;
}

.btn-primary:hover {
  background-color: #fcac45;
  border: 1px solid #fcac45;
  box-shadow: 6px 6px #fcac4580;
}

.btn-primary:focus, .btn-primary:active {
  background-color: #fcac45;
  border: 1px solid #fcac45;
  outline: none !important;
}

.btn-secondary {
  background-color: #fdfdf8;
  border: 1px solid #b4b4b3;
  color: #9d9d9b;
  font-weight: bold;
  font-size: 18px;
  transition: box-shadow 0.5s;
}

.btn-secondary:hover {
  background-color: #fdfdf8;
  border: 1px solid #b4b4b3;
  color: #9d9d9b;
  box-shadow: 6px 6px #9d9d9b80;
}

.btn-secondary:focus, .btn-secondary:active {
  background-color: #fdfdf8;
  border: 1px solid #b4b4b3;
  color: #9d9d9b;
  outline: none !important;
}

.bi {
  margin-bottom: 5px;
  margin-right: 5px;
}

.bi:hover {
  cursor: pointer;
}

@media (max-width: 600px) {
  .bi {
    margin-right: -10px;
  }
}

.bi-caret-up-fill {
  background-color: #fcac45;
  margin: auto;
}

.bi-caret-up-fill:hover {
  background-color: #fcac45;
}

.bi-caret-up-empty {
  background-color: #fdfdf8;
  margin: auto;
}

.bi-caret-up-empty:hover {
  background-color: #fdfdf8;
}

@media (max-width: 505px) {
  .bi-caret-up-fill, .bi-caret-up-empty {
    margin-right: 3px;
  }
}

@media (max-width: 505px) {
  #upvote {
    display: none;
  }
}

@media (min-width: 505px) {
  #upvote {
    font-size: 14px;
    height: 65px;
    width: 50px;
    margin-top: 28px;
    margin-left: 10px;
  }
}

@media (max-width: 505px) {
  #upvote-mobile {
    font-size: 12px;
    width: 80px;
    height: 25px;
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 3px;
    padding: 0px;
  }
}

@media (min-width: 505px) {
  #upvote-mobile {
    display: none;
  }
}

.upvote-button {
  height: 70px;
  width: 50px;
}

.anchor {
  text-decoration: none;
  color: rgb(36, 36, 36);
}

.anchor:hover {
  color: rgb(36, 36, 36);
}

@media (min-width: 1200px) {
  .container {
    width: 65%;
  }
}

.sub-container {
  margin-bottom: 20px;
  padding: 20px;
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
}

.sub-container-no-border {
  padding: 20px 20px 10px;
  width: 100%;
}

.p-2 {
  display: inline-flex;
  width: 100%;
}

.logo {
  height: 120px;
  width: 120px;
  border-radius: 100px;
  margin: 5px;
}

.icon-large {
  height: 120px;
  width: 120px;
  border-radius: 10px;
  margin: 5px;
}

.icon-small {
  height: 90px;
  width: 90px;
  border-radius: 10px;
  margin: 5px;
}

.header-title {
  font-family: Avenir, ui-sans-serif, system-ui;
  margin-top: 15px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.sub-header {
  width: 100%;
  color: rgb(36, 36, 36);
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 22px;
  font-weight: 500;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 0px;
}

.sub-title {
  color: rgb(36, 36, 36);
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 18px;
  margin-left: 10px;
  margin-bottom: 8px;
}

.sub-title-sm {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
  margin-bottom: 10px;
}

#indent {
  margin-left: 30px;
}

.p-font {
  font-family: Avenir, ui-sans-serif, system-ui;
  margin-left: 10px;
  font-size: 16px;
  white-space: pre-wrap;
}

.mission-title {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 24px;
  font-weight: 600;
  color: #fcac45;
  margin-left: 30px;
  margin-bottom: 15px;
}

.mission-body {
  background-color: #fdfdf8;
  border-radius: 10px;
  box-shadow: 2px 2px #dcdcdc;
  padding-bottom: 2px;
}

@media (min-width: 800px) {
  .about-p-bold {
    font-family: Avenir, ui-sans-serif, system-ui;
    font-size: 34px;
    font-weight: 600;
    line-height: 46px;
    margin-left: 5px;
    color: #fcac45;
  }
}

@media (max-width: 800px) {
  .about-p-bold {
    font-family: Avenir, ui-sans-serif, system-ui;
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
    margin-left: 5px;
    color: #fcac45;
  }
}

.about-body {
  max-width: 95%;
  margin: auto;
  border-left: 5px solid #fcac45;
}

@media (min-width: 800px) {
  .about-p {
    font-family: Avenir, ui-sans-serif, system-ui;
    font-size: 22px;
    line-height: 36px;
    white-space: pre-wrap;
  }
}

@media (max-width: 800px) {
  .about-p {
    font-family: Avenir, ui-sans-serif, system-ui;
    font-size: 18px;
    line-height: 28px;
    white-space: pre-wrap;
  }
}

.about-cta-div {
  background-color: #fdfdf8;
  border-radius: 10px;
  box-shadow: 2px 2px #dcdcdc;
  align-items: center;
  padding: 20px;
}

.about-cta-body {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #fcac45;
  margin: 0px 0px 18px;
  width: 100%;
}

.about-cta-button-div-no-auth {
  width: 330px;
  justify-content: space-between;
  margin: auto;
  margin-bottom: 5px;
}

.about-cta-button-div-auth {
  width: 330px;
  justify-content: center;
  margin: auto;
  margin-bottom: 5px;
}

.about-cta-button {
  font-size: 20px;
  width: 150px;
  padding: 8px;
}

#container-tabs {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 12px;
  padding-bottom: 8px;
  border-top: 2px solid #dcdcdc; 
  border-bottom: 2px solid #dcdcdc;
  justify-content: center;
}

.tab-link {
  font-family: Avenir, ui-sans-serif, system-ui;
  color: rgb(36, 36, 36);
  margin-right: 5px;
  padding-left: 8px;
}

#dull-icon {
  color: rgb(132, 132, 132);
  pointer-events: none;
}

@media (max-width: 600px) {
  .mobile-tab {
    display: none;
  }
}

.mobile-tab:hover {
  cursor: pointer;
}

.tab-link-end {
  font-family: Avenir, ui-sans-serif, system-ui;
  color: rgb(36, 36, 36);
  margin-left: 35px;
  margin-bottom: 6px;
  float: right;
}

@media (max-width: 800px) {
  .tab-link-end {
    display: none;
  }
}

#mobile-link {
  display: none;
}

@media (max-width: 800px) {
  #mobile-link {
    display: inline;
  }
}

.tab-link-div {
  padding: 10px;
  margin: 0px 0px;
  width: 90%;
}

.tab-link-end-div {
  width: 100%;
  float: right;
}

.project-link-div {
  padding: 10px;
  margin-left: 35px;
}

.bootcamp-website {
  margin-right:5px;
  margin-bottom: 3px;
}

.project-link {
  font-family: Avenir, ui-sans-serif, system-ui;
  color: rgb(36, 36, 36);
  text-decoration: none;
  margin-left: 5px;
}

.project-link:hover {
  font-family: Avenir, ui-sans-serif, system-ui;
  text-decoration: underline;
}

#links-right {
  margin: 5px;
  float: right;
}

.filter-div {
  width: 100%;
  align-items: center;
}

@media (min-width: 500px) {
  .filter-dropdown {
    margin: 0px 30px 5px 20px;
  }
}

@media (max-width: 500px) {
  .filter-dropdown {
    display: none;
  }
}

@media (min-width: 500px) {
  .filter-dropdown-mobile {
    display: none;
  }
}

@media (max-width: 500px) {
  .filter-dropdown-mobile {
    display: block;
    margin-top: 10px;
    margin-left: 30px;
  }
}

.project-card {
  position: relative;
  background: linear-gradient(225deg, #fdfdf8 0%, #fdfdf8 50%);
  padding: 20px 25px 20px 20px;
  margin-bottom: 25px;
  border-radius: 10px;
  z-index: 1;
}

.project-card::before {
  position: absolute;
  background: linear-gradient(190deg, #fcac4580 0%, #fdfdf8 50%);
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  transition: opacity 0.6s ease-in-out;
  z-index: -1;
  opacity: 0;
}

.project-card:hover::before {
  opacity: 1;
}

.project-card-div {
  width: 100%;
  align-items: center;
  background-color: transparent;
}

@media (max-width: 500px) {
  .project-card-div-mobile {
    width: 105px;
  }
}

.card-sub-title {
  color: rgb(36, 36, 36);
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 22px;
  font-weight: 500;
  margin-left: 10px;
  margin-bottom: 10px;
}

.card-p-font {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 16px;
  margin-left: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.circle-fill-profile {
  background-color: #f3f3eb;
  margin-top: 3px;
}

.circle-fill-card {
  background-color: #fdfdf8;
}

.space-for-socials {
  width: 100%;
}

.socials {
  width: 120px;
}

.chip {
  display: inline-flex;
  padding: 0px 10px;
  margin: 0px 10px 6px 0px; 
  height: 20px;
  font-size: 16px;
  border-radius: 25px;
  background-color: #eeeeea;
  cursor: default;
}

.chip img {
  flex-shrink: 0;
  float: left;
  margin: 0px 5px 0px -10px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #eeeeea;
  border: 1px solid #eeeeea;
}

.chip p {
  flex-shrink: 0;
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 12px;
  font-weight: 500;
  margin: 2px 0px 0px 0px;
  background-color: #eeeeea;
}

.tag-div {
  flex-wrap: wrap;
  padding: 10px 10px 0px;
  align-items: center;
}

.profile-chip {
  display: inline-flex;
  padding: 0px 10px;
  margin: 0px 10px 6px 0px; 
  height: 20px;
  font-size: 16px;
  border-radius: 25px;
  background-color: #fff;
}

#bootcamp-chip {
  margin-left: 10px;
}

.profile-chip img {
  flex-shrink: 0;
  float: left;
  margin: 0px 5px 0px -10px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #fdfdf8;
}

.profile-chip p {
  flex-shrink: 0;
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 12px;
  font-weight: 500;
  margin: 2px 0px 0px 0px;
  background-color: #fff;
}

.profile-tag-div {
  flex-wrap: wrap;
  padding: 5px 10px 5px;
  align-items: center;
}

@media (max-width: 600px) {
  #hide-program-name {
    display: none;
  }
}

.account-container {
  flex-wrap: wrap;
  max-width: 1100px;
  margin: auto;
}

.team-container {
  flex-wrap: wrap;
  max-width: 1100px;
  margin: auto;
}

@media (min-width: 500px) {
  .account-card {
    justify-self: stretch;
    position: relative;
    background: linear-gradient(225deg, #fdfdf8 0%, #fdfdf8 50%);
    min-width: 390px;
    padding: 20px 25px 20px 20px;
    margin: 0px 5px 25px;
    border-radius: 10px;
    z-index: 1;
  }
}

@media (max-width: 500px) {
  .account-card {
    justify-self: stretch;
    position: relative;
    background: linear-gradient(225deg, #fdfdf8 0%, #fdfdf8 50%);
    min-width: 310px;
    padding: 20px 25px 20px 20px;
    margin: 0px 5px 25px;
    border-radius: 10px;
    z-index: 1;
  }
}

.account-card::before {
  position: absolute;
  background: linear-gradient(190deg, #fcac4580 0%, #fdfdf8 50%);
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  transition: opacity 0.6s ease-in-out;
  z-index: -1;
  opacity: 0;
}

.account-card:hover::before {
  opacity: 1;
}

.account-card-filler {
  justify-self: stretch;
  position: relative;
  background: transparent;
  height: 10px;
  min-width: 420px;
  padding: 20px 25px 20px 20px;
  margin: 0px 5px 25px;
  border-radius: 10px;
  z-index: 1;
}

.anchor-wrap {
  margin: auto;
}

.card {
  background-color: transparent;
  margin: 5px;
  border: none;
  cursor: pointer;
}

.account-card-div {
  width: 100%;
  align-items: center;
  background-color: transparent;
}

.account-card-div-left {
  width: 105px;
}

.profile-icon {
  height: 80px;
  width: 80px;
  border-radius: 50px;
  margin: 5px;
}

.account-links {
  margin-left: 25px;
  width: 100px;
}

@media (max-width: 605px) {
  .account-links {
    display: none;
  }
}

@media (max-width: 500px) {
  #hide-account-tags {
    display: none;
  }
}

@media (max-width: 505px) {
  #account-follow {
    display: none;
  }
}

@media (min-width: 505px) {
  #account-follow {
    font-size: 15px;
    width: 95px;
    height: 32px;
    margin-left: 10px;
    margin-bottom: 3px;
    padding: 0px;
    float: right;
  }
}

@media (max-width: 505px) {
  #follow-mobile {
    font-size: 12px;
    width: 80px;
    height: 25px;
    margin-top: 5px;
    margin-left: 5px;
    margin-bottom: 3px;
    padding: 0px;
  }
}

@media (min-width: 505px) {
  #follow-mobile {
    display: none;
  }
}

#account-cta-div {
  background-color: #fdfdf8;
  width: 100%;
  padding: 30px;
  margin-bottom: 25px;
  border-radius: 10px;
}

.account-cta {
  font-family: Avenir, ui-sans-serif, system-ui;
  text-align: center;
  width: 100%;
}

.account-cta-button {
  font-family: Avenir, ui-sans-serif, system-ui;
  display: block;
  width: 180px;
  margin: 0 auto;
  margin-top: 20px;
}

.carousel-container {
  margin-top: 10px;
}

.main-image-div {
  max-width: 100%;
}

.main-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 12px 12px #dcdcdc;
  margin-bottom: 30px;
}

.main-image:hover {
  cursor: pointer;
}

@media (min-width: 700px) {
  .vertical-carousel {
    max-height: 450px;
    overflow: scroll;
    mask-image: linear-gradient(to bottom, black calc(100% - 200px), transparent 100%);
  }
}

@media (max-width: 700px) {
  .vertical-carousel {
    max-height: 250px;
    overflow: scroll;
    mask-image: linear-gradient(to bottom, black calc(100% - 200px), transparent 100%);
  }
}

.thumbnails-div {
  max-width: 100%;
  height: auto;
  padding-right: 5px;
}

.thumbnails {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 5px 5px #dcdcdc;
}

.thumbnails:hover {
  cursor: pointer;
}

.video {
  overflow: hidden;
  position: relative;
  height: 0px;
  padding-bottom: 56.25%;
  margin: auto;
  margin-bottom: 20px;
  border-radius: 10px;
}

.video iframe {
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  position: absolute;
}

@media (max-width: 767px) {
  .modal {
    background: #fdfdf8;
  }
}

.modal-styling {
  background: #f3f3eb;
  border-radius: 5px;
  padding-bottom: 25px;
}

.follow-modal-styling {
  background: #f3f3eb;
  border-radius: 5px;
}

.follow-modal-body {
  padding: 20px 20px 15px 20px;
}

.carousel {
  margin-bottom: -40px;
}

.popup-image {
  border-radius: 10px;
  width: 100px;
  height: auto;
  overflow: hidden;
  background-position: center;
  background-color: transparent;
}

.auth-header {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 24px;
  font-weight: 600;
  color: #fcac45;
}

.login-signup-p {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 14px;
}

.login-signup-cta {
  font-weight: 500;
  color: #fcac45;
}

.login-signup-cta:hover {
  cursor: pointer;
}

.profile-info {
  width: 100%;
  margin-right: 20px;
}

.profile-icon-mini {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  margin: -5px 5px -3px 5px;
}

.profile-button-div {
  display: block;
  margin-top: 20px;
  margin-left: 5px;
}

.profile-button-div p {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 12px;
  text-align: center;
  line-height: 8px;
  margin-top: 15px;
}

@media (min-width: 500px) {
  #profile-button {
    display: block;
    margin: auto;
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  #profile-button {
    display: none;
  }
}

@media (min-width: 500px) {
  #profile-button-mobile {
    display: none;
  }
}

@media (max-width: 500px) {
  #profile-button-mobile {
    display: block;
    margin: auto;
    font-size: 14px;
    height: 32px;
    width: 85px;
    margin-top: 10px;
    padding: 0px;
  }
}

#edit-project-button {
  width: 120px;
}

.follow-button-div {
  display: block;
  margin-top: 20px;
  margin-left: 5px;
}

.follow-button-div p {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 12px;
  text-align: center;
  line-height: 8px;
  margin-top: 15px;
}

@media (min-width: 500px) {
  .follow-button {
    display: block;
    margin: auto;
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  .follow-button {
    display: none;
  }
}

@media (min-width: 500px) {
  .follow-button-mobile {
    display: none;
  }
}

@media (max-width: 500px) {
  .follow-button-mobile {
    font-family: Avenir, ui-sans-serif, system-ui;
    display: block;
    margin: auto;
    font-size: 14px;
    height: 30px;
    width: 88px;
    margin-top: 10px;
    padding: 4px 0px 0px;
  }
}

@media (min-width: 500px) {
  .follow-div {
    margin: 5px 5px 0px;
    display: block;
    margin: auto;
    padding: 0px 5px;
  }
}

@media (max-width: 500px) {
  .follow-div {
    display: none;
  }
}

#follow-count-pointer {
  font-size: 15px;
}

#follow-count-pointer:hover {
  cursor: pointer;
  text-decoration: underline;
}

#follow-pointer:hover {
  cursor: pointer;
  text-decoration: underline;
}

#follow-count {
  font-size: 15px;
}

@media (min-width: 770px) {
  .bootcamp-metrics-div {
    display: block;
    padding: 5px;
    margin-top: 20px;
    margin-left: 5px;
  }
}

@media (max-width: 770px) {
  .bootcamp-metrics-div {
    display: none;
  }
}

.bootcamp-metrics-div p {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 11px;
  text-align: center;
  line-height: 8px;
  margin-top: 15px;
}

#bootcamp-metrics {
  font-size: 16px;
  padding-top: 3px;
}

.bootcamp-metrics-left-div {
  background-color: #fdfdf8;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: block;
  width: 50px;
  margin: auto;
  padding: 0px 0px 0px 5px;
}

.bootcamp-metrics-right-div {
  background-color: #fdfdf8;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: block;
  width: 50px;
  margin: auto;
  padding: 0px 5px 0px 0px;
}

.bootcamp-upvote-div {
  background-color: #fdfdf8;
  border-radius: 10px;
  margin: 5px 5px 0px;
  display: block;
  height: 80px;
  margin: auto;
  padding: 5px;
}

#bootcamp-upvote-count {
  color: #fcac45;
  font-size: 22px;
  font-weight: 800;
  width: 48px;
  margin-top: 22px;
}

#bootcamp-upvote-label {
  color: #fcac45;
  font-size: 11px;
  font-weight: 800;
}

.form-div {
  border-bottom: 1px solid #dcdcdc;
}

.form-title {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 24px;
  font-weight: 600;
  color: #fcac45;
  margin-bottom: 15px;
}

@media (min-width: 500px) {
  .form-back-to-profile {
    font-family: Avenir, ui-sans-serif, system-ui;
    font-size: 14px;
    color: black;
    margin-top: 8px;
    margin-left: 10px;
    text-decoration: none;
  }

  .form-back-to-profile:hover {
    color: black;
    text-decoration: underline;
    cursor: pointer;
  }
}

@media (max-width: 500px) {
  .form-back-to-profile {
    display: none;
  }
}

.form-header {
  width: 100%;
  color: rgb(36, 36, 36);
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

#bio {
  margin-bottom: 30px;
}

#bio-title {
  margin-top: 5px;
}

label {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 16px;
  color: rgb(36, 36, 36);
  margin: 5px 0px 5px 2px;
}

input, textarea, select {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 14px;
  color: rgb(36, 36, 36);
}

.instructions {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 14px;
  color: rgb(36, 36, 36);
  margin: 0px 0px 8px 2px;
}

.instructions-sm {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 14px;
  font-style: italic;
  color: rgb(36, 36, 36);
  margin-top: 0px;
  margin-bottom: 8px;
  margin-left: 5px;
}

.embed-instructions-click {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 14px;
  text-decoration: underline;
  color: rgb(36, 36, 36);
  margin: 0px 0px 8px 2px;
}

.embed-instructions-click:hover {
  cursor: pointer;
}

.embed-instructions {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 14px;
  color: rgb(36, 36, 36);
  margin: 0px 0px 8px 8px;
}

.embed-example {
  background-color: rgba(255, 255, 0, 0.60);
}

.icon-field {
  height: 45px;
  margin-top: 8px;
}

.profile-picture-preview {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  margin-right: 5px;
}

.icon-preview {
  height: 45px;
  width: 45px;
  border-radius: 10%;
}

.icon-update {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 14px;
  margin-top: 12px;
  margin-left: 10px;
}

.icon-update:hover {
  text-decoration: underline;
  cursor: pointer;
}

.image-field {
  height: 90px;
  margin-top: 16px;
}

.image-preview {
  height: 90px;
  width: auto;
  border-radius: 10%;
}

.add-image-cta {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 14px;
  float: right;
}

.add-image-cta:hover {
  text-decoration: underline;
  cursor: pointer;
}

.image-update {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 16px;
  margin-top: 32px;
  margin-left: 20px;
}

.image-update:hover {
  text-decoration: underline;
  cursor: pointer;
}

.image-removal-div {
  display: block;
}

.image-removal {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 16px;
  color: red;
  margin: 30px 0px 0px 20px;
}

.image-removal-undo {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 14px;
  margin-left: 20px;
}

.image-removal-undo:hover {
  text-decoration: underline;
  cursor: pointer;
}

.image-input-map {
  margin-bottom: 20px;
}

#screenshot-buffer {
  margin: 30px 0px;
}

.tags-form {
  margin-top: 20px;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .tags-select-desktop {
    height: 155px;
    margin-bottom: 35px;
  }
}

@media (max-width: 767px) {
  .tags-select-desktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .tags-select-mobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .tags-select-mobile {
    margin-bottom: 35px;
  }
}

#tag-form-chip {
  padding: 0px 10px;
  margin: 0px 10px 10px 0px; 
  box-shadow: 2px 2px #dcdcdc;
}

#tag-form-chip:hover {
  cursor: pointer;
}

#tags-container {
  padding-top: 8px;
  padding-bottom: 0px;
}

.tags {
  margin-bottom: 20px;
  margin-left: 5px;
}

#tag-chip {
  padding: 0px 10px;
  margin: 0px 10px 10px 0px; 
  box-shadow: 2px 2px #dcdcdc;
}

.testimonial-div {
  margin-top: 5px;
  margin-left: 10px;
}

.quotation-div {
  height: 35px;
  width: 35px;
}

#testimonial-author {
  margin: 0px 0px 30px 60px;
}

@media (min-width: 768px) {
  .team-members-select-desktop {
    height: 100px;
    margin-bottom: 35px;
  }
}

@media (max-width: 767px) {
  .team-members-select-desktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .team-members-select-mobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .team-members-select-mobile {
    margin-bottom: 35px;
  }
}

.team-member-chip {
  display: inline-flex;
  padding: 0px 10px;
  margin: 0px 15px 9px 0px; 
  height: 30px;
  font-size: 24px;
  border-radius: 25px;
  background-color: #fff;
}

.team-member-chip img {
  flex-shrink: 0;
  float: left;
  margin: 0px 7.5px 0px -15px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1.5px solid #fdfdf8;
}

.team-member-no-profile-pic {
  flex-shrink: 0;
  float: left;
  margin: 0px 7.5px 0px -15px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1.5px solid #fdfdf8;
}

.team-member-chip p {
  flex-shrink: 0;
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 18px;
  font-weight: 500;
  margin: 3px 0px 0px 0px;
  background-color: #fff;
}

#team-member-form-chip {
  padding: 0px 15px;
  margin: 0px 15px 15px 0px; 
  box-shadow: 3px 3px #dcdcdc;
}

#team-member-no-click {
  padding: 0px 15px;
  margin: 0px 15px 15px 0px; 
  box-shadow: 3px 3px #dcdcdc;
  pointer-events: none;
}

#team-member-form-chip:hover {
  cursor: pointer;
}

.project-follow-button {
  display: block;
  margin: auto;
  font-size: 16px;
}

#disable-follow {
  pointer-events: none;
  background-color: #fcac4590;
  border: none;
}

.follow-line-item {
  position: relative;
  background: linear-gradient(225deg, #fdfdf8 0%, #fdfdf8 50%);
  padding: 8px 12.5px 8px 12.5px;
  margin-bottom: 10px;
  border-radius: 10px;
  z-index: 1;
}

.follow-line-item::before {
  position: absolute;
  background: linear-gradient(190deg, #fcac4580 0%, #fdfdf8 50%);
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  transition: opacity 0.6s ease-in-out;
  z-index: -1;
  opacity: 0;
}

.follow-line-item:hover::before {
  opacity: 1;
  cursor: pointer;
}

#follow-profile-picture {
  border-radius: 50px;
  height: 45px;
  width: 45px;
  margin-top: 3px;
}

#follow-profile-picture:hover {
  cursor: pointer;
}

#follow-name {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 16px;
  margin-top: 12px;
  margin-left: 10px;
}

#follow-name:hover {
  cursor: pointer;
}

.footer-margin {
  height: 0;
}

.footer-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #dcdcdc;
  margin-top: 10px;
  padding: 20px 20px 8px;
  background-color: #f3f3eb;
  z-index: 1;
}

.footer-div {
  display: inline-flex;
}

.footer-div p {
  font-family: Avenir, ui-sans-serif, system-ui;
  font-size: 14px;
}